(function(){

if (!document.querySelector ||
	!document.addEventListener ||
    !("classList" in document.createElement("a"))) {
	return false;
}

var menu = document.querySelector(".menu");
var menuLink = menu.querySelector(".menu__button");

menuLink.addEventListener("click", function(e) {
	menu.classList.toggle("menu_open");
	open = true;
}, false);

menuLink.addEventListener("click", function(e) {
	e.preventDefault();
	return false;
}, false);

// hide footer menu
document.querySelector(".footer__menu").classList.add("footer__menu_hidden");

})();