(function(){
	var testNode = document.createElement("a");
    if (!document.querySelector ||
        !document.addEventListener ||
        !("dataset" in testNode) ||
        !("classList" in testNode)) {
        return;
    }

	var container = document.querySelector(".products__list");
	if (!container) {
		return;
	}

    var iso;
    imagesLoaded(document.querySelectorAll(".products__img"), function() {
    	iso = new Isotope( container, {
            itemSelector: ".products__item",
            layoutMode: "fitRows"
        });
    });

    var currentFilter = "*";

	var filters = document.querySelectorAll(".filters__item");
	for (var i = 0; i < filters.length; i++) {
		filters[i].addEventListener("click", clickFilter, false);
	}
    function clickFilter(e) {
        e.preventDefault();
        var filter = "." + e.target.dataset.filter != currentFilter ?
            "." + e.target.dataset.filter :
            "*";
        iso.arrange({filter: filter});
        currentFilter =  filter;
        for (var i = 0; i < filters.length; i++) {
        	filters[i].classList.remove("filters__item_selected");
        }
        if (filter != "*") {
            e.target.classList.add("filters__item_selected");
        }
        return false;
    }

})();